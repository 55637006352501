import DashboardLayout from "@/pages/layouts/DashboardLayout";

let brandsRoutes = {
  path: '/brands',
  component: DashboardLayout,
  redirect: '/brands/list',
  children: [
    {
      path: 'list',
      name: 'Brands List',
      component:()=>import(/* webpackChunkName: "brands" */ '@/pages/product/brands/BrandsList'),
      meta: {
        permissionCode: 'brands/index',
      }
    },
    {
      path: 'create',
      name: 'Add Brand',
      component:()=>import(/* webpackChunkName: "brands" */ '@/pages/product/brands/BrandForm'),
      meta: {
        permissionCode: 'brands/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Brand',
      component:()=>import(/* webpackChunkName: "brands" */ '@/pages/product/brands/BrandForm'),
      meta: {
        permissionCode: 'brands/update',
      }
    }
  ]
}

export default brandsRoutes;
