import DashboardLayout from "@/pages/layouts/DashboardLayout";

let contactusRoutes = {
  path: '/contact-us',
  component: DashboardLayout,
  redirect: '/contact-us/list',
  children: [
    {
      path: 'list',
      name: 'Contact Messages List',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/general/contactus/ContactusList'),
      meta: {
        permissionCode: 'contact-us/index',
      }
    },
  ]
}

export default contactusRoutes;
