import DashboardLayout from "@/pages/layouts/DashboardLayout";

let recipesRoutes = {
  path: '/recipes',
  component: DashboardLayout,
  redirect: '/recipes/list',
  children: [
    {
      path: 'list',
      name: 'Recipes List',
      component:()=>import(/* webpackChunkName: "recipes" */ '@/pages/recipes/RecipesList'),
      meta: {
        permissionCode: 'recipes/index',
      }
    },
    {
      path: 'create',
      name: 'Add Recipe',
      component:()=>import(/* webpackChunkName: "recipes" */ '@/pages/recipes/RecipeForm'),
      meta: {
        permissionCode: 'recipes/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Recipe',
      component:()=>import(/* webpackChunkName: "recipes" */ '@/pages/recipes/RecipeForm'),
      meta: {
        permissionCode: 'recipes/update',
      }
    }
  ]
}

export default recipesRoutes;
