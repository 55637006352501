import DashboardLayout from "@/pages/layouts/DashboardLayout";

let categoriesRoutes = {
  path: '/categories',
  component: DashboardLayout,
  redirect: '/categories/list',
  children: [
    {
      path: 'list',
      name: 'Categories List',
      component:()=>import(/* webpackChunkName: "categories" */ '@/pages/product/categories/CategoriesList'),
      meta: {
        permissionCode: 'categories/index',
      }
    },
    {
      path: 'create',
      name: 'Add Category',
      component:()=>import(/* webpackChunkName: "categories" */ '@/pages/product/categories/CategoryForm'),
      meta: {
        permissionCode: 'categories/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Category',
      component:()=>import(/* webpackChunkName: "categories" */ '@/pages/product/categories/CategoryForm'),
      meta: {
        permissionCode: 'categories/update',
      }
    }
  ]
}

export default categoriesRoutes;
