import DashboardLayout from "@/pages/layouts/DashboardLayout";

let productsRoutes = {
  path: '/products',
  component: DashboardLayout,
  redirect: '/products/list',
  children: [
    {
      path: 'list',
      name: 'Products List',
      component:()=>import(/* webpackChunkName: "products" */ '@/pages/product/products/ProductsList'),
      meta: {
        permissionCode: 'products/index',
      }
    },
    {
      path: 'create',
      name: 'Add Product',
      component:()=>import(/* webpackChunkName: "products" */ '@/pages/product/products/ProductForm'),
      meta: {
        permissionCode: 'products/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Product',
      component:()=>import(/* webpackChunkName: "products" */ '@/pages/product/products/ProductForm'),
      meta: {
        permissionCode: 'products/update',
      }
    }
  ]
}

export default productsRoutes;
